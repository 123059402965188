import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

function Footer() {
    return (
        <footer className="uk-section uk-light" style={{ backgroundColor: "#ef4123" }}>
            <div className="uk-container uk-container-medium">
                <div className="uk-grid">
                    <div className="uk-width-1-3@m uk-width-1-1 uk-text-center">
                        <Link to="/">
                            <StaticImage
                                alt="Hopster!"
                                src="../../static/images/white.png"
                                placeholder="tracedSVG"
                                width={150}
                            />
                        </Link>

                        <div className="uk-flex uk-flex-center uk-margin-small-top">
                            <a href="https://www.facebook.com/hopsterTV" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    alt="Facebook"
                                    height={35}
                                    placeholder="tracedSVG"
                                    src="../../static/images/facebook.png"
                                    width={35}
                                />
                            </a>

                            <a href="https://www.instagram.com/hopstertv/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    alt="Instagram"
                                    height={35}
                                    placeholder="tracedSVG"
                                    src="../../static/images/instagram.png"
                                    width={35}
                                />
                            </a>

                            <a
                                href="https://www.linkedin.com/company/sandbox-kids"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <StaticImage
                                    alt="Linkedin"
                                    height={35}
                                    placeholder="tracedSVG"
                                    src="../../static/images/linkedin.png"
                                    width={35}
                                />
                            </a>

                            <a href="https://twitter.com/hopsterTV" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    alt="Twitter"
                                    height={35}
                                    placeholder="tracedSVG"
                                    src="../../static/images/twitter.png"
                                    width={35}
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCo8eceFz-Yl0xJ2M6PApyGg"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <StaticImage
                                    alt="Youtube"
                                    height={35}
                                    placeholder="tracedSVG"
                                    src="../../static/images/youtube.png"
                                    width={35}
                                />
                            </a>
                        </div>
                    </div>

                    <div className="uk-width-1-3@m uk-width-1-1 uk-margin-top">
                        <div className="uk-flex uk-flex-column uk-text-center">
                            <h6 className="uk-margin-remove">
                                <a href="https://club.hopster.tv/#/login/">Login</a>
                            </h6>

                            <h6 className="uk-margin-remove">
                                <a href="https://blog.hopster.tv/" target="_blank" rel="noreferrer">
                                    Blog
                                </a>
                            </h6>

                            <h6 className="uk-margin-remove">
                                <a href="https://fp-promo.onelink.me/Z6bz/2d29b054">Download</a>
                            </h6>

                            <h6 className="uk-margin-remove">
                                <a href="https://help.playkids.com" target="_blank" rel="noreferrer">
                                    Help & FAQs
                                </a>
                            </h6>
                        </div>
                    </div>

                    <div className="uk-width-1-1 uk-margin-medium-top uk-text-center" style={{ color: "white" }}>
                        <div className="text-center p-3 text-final-footer">
                            © {new Date().getFullYear()} Plato Media Ltd. All rights reserved.
                            <Link activeClassName="white" to="/privacy">
                                {" "}
                                Privacy Policy{" "}
                            </Link>
                            and
                            <Link activeClassName="white" to="/terms-of-use">
                                {" "}
                                Terms of Use.{" "}
                            </Link>
                            Hopster is part of
                            <a className="white" href="https://sbxgroup.com/kids" target="_blank" rel="noreferrer">
                                {" "}
                                Sandbox Kids.
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
