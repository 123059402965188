import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Sticky from "./sticky";

function Navbar() {
    return (
        <Sticky clsActive="uk-navbar-sticky">
            <nav className="uk-navbar-container">
                <div className="uk-container uk-container-medium">
                    <div className="uk-grid uk-child-width-1-2">
                        <div className="uk-flex uk-flex-left uk-flex-middle">
                            <Link activeClassName="uk-logo" to="/">
                                <StaticImage
                                    alt="logo"
                                    placeholder="none"
                                    src="../../static/images/hopster-pink.png"
                                    width={100}
                                />
                            </Link>
                        </div>

                        <div className="uk-flex uk-flex-right uk-flex-middle">
                            <ul className="uk-navbar-nav">
                                <li>
                                    <a href="https://club.hopster.tv/#/login/">Login</a>
                                </li>
                                <li>
                                    <a href="https://fp-promo.onelink.me/Z6bz/2d29b054">Download</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </Sticky>
    );
}

export default Navbar;
