import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import UIkit from "uikit";

function Sticky(props) {
    const { animation, children, clsActive, clsInactive, media, offset, top } = props;
    const ukSticky = useRef();

    useEffect(() => {
        if (!ukSticky.current) {
            ukSticky.current = UIkit.sticky("#sticky", {
                animation,
                clsActive,
                clsInactive,
                media,
                offset,
                top
            });
        } else {
            // eslint-disable-next-line no-global-assign
            ukSticky.current.$emit((event = "update"));
        }
    });

    return (
        <div id="sticky" style={{ position: "relative", zIndex: 980 }}>
            {children}
        </div>
    );
}

Sticky.defaultProps = {
    animation: "",
    clsActive: "uk-active",
    clsInactive: "",
    media: "false",
    offset: 0,
    top: 0
};

Sticky.propTypes = {
    animation: PropTypes.string,
    children: PropTypes.node.isRequired,
    clsActive: PropTypes.string,
    clsInactive: PropTypes.string,
    media: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offset: PropTypes.number,
    top: PropTypes.number
};

export default Sticky;
