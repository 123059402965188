import "../../static/scss/base.scss";

import Helmet from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

import Footer from "./footer";
import Navbar from "./navbar";

import imgPromo from "../../static/images/promo.png";
import { useEffect } from "react";

function Layout(props) {
    const { disableNavBar, children, ...defaultProps } = props;

    useEffect(() => {
        window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "yidhmciw"
        };

        (() => {
            const w = window;
            const ic = w.Intercom;
            if (typeof ic === "function") {
                ic("reattach_activator");
                ic("update", w.intercomSettings);
            } else {
                const d = document;
                const i = args => {
                    i.c(args);
                };
                i.q = [];
                i.c = args => {
                    i.q.push(args);
                };
                w.Intercom = i;
                const l = () => {
                    const s = d.createElement("script");
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = "https://widget.intercom.io/widget/yidhmciw";
                    const x = d.getElementsByTagName("script")[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === "complete") {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent("onload", l);
                } else {
                    w.addEventListener("load", l, false);
                }
            }
        })();
    }, []);

    return (
        <main>
            <Helmet>
                <meta charset="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <title>Hopster - Making screentime SMART!</title>

                <meta name="facebook-domain-verification" content="grxdsph5blff6n5109phqrg0meyhxw" />

                <meta property="og:description" content="Making screentime SMART!" />
                <meta property="og:image" content={imgPromo} />
                <meta property="og:site_name" content="Hopster" />
                <meta property="og:title" content="Hopster" />
                <meta property="og:url" content="https://www.hopster.tv" />

                <meta name="twitter:card" content="Making screentime SMART!" />
                <meta name="twitter:title" content="Hopster" />
                <meta name="twitter:image" content={imgPromo} />
                <meta name="twitter:url" content="https://www.hopster.tv" />
            </Helmet>

            {!disableNavBar && <Navbar />}

            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <div {...defaultProps}>{children}</div>
            <Footer />
        </main>
    );
}

Layout.defaultProps = {
    disableNavBar: false,
    style: {}
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    disableNavBar: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.string)
};

export default Layout;
